module.exports =
  ({ node, key, value }) =>
  (doc) => {
    // console.log(doc.type);
    // if (doc.type === "home") {
    //   return "/";
    // }

    switch (doc.type) {
      case "home":
        return `/`;

      case "category":
        return `/category/${doc.uid}`;

      case "product":
        return `/product/${doc.uid}`;

      case "project":
        // return `/project/${doc.uid}`;
        return `/category/all/#${doc.uid}`;

      default:
        return `/${doc.uid}`;
    }
  };
